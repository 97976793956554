<template>
  <div id="manageRole" class="Content-Page">
    <div id="namePage" class="wrap-Main">
      <div class="box-S4 flex-between-center">
        <div class="N-Page T-size-36">Manage Fuel Station</div>
        <v-btn class="theme-btn-even" @click.stop="addItem()">
          <span class="I-create"></span>
          <span>Add Fuel Station</span>
        </v-btn>
      </div>
    </div>

    <div id="tableData" class="wrap-Main">
      <div class="box-S4">
        <v-data-table :headers="headers" :items="itemsdata">
          <template v-slot:item.md="{ item }">
            <div v-if="item.fus_name != 'TOTAL'">
              {{ item.md | yyyyMMddHHmm }}
            </div>
          </template>

          <template v-slot:item.action="{ item }" class="text-xs-right">
            <div class="b-action">
              <div class="b-btn">
                <v-btn
                  fab
                  class="btn-edit"
                  @click="editItem(item)"
                  :disabled="
                    readonly_shore || readonly_flight || readonly_pilot
                  "
                >
                  <span class="I-edit"></span>
                </v-btn>
                <v-btn
                  fab
                  class="primary btn-delete"
                  @click="deleteItem(item)"
                  :disabled="
                    readonly_shore || readonly_flight || readonly_pilot
                  "
                >
                  <span class="I-bin"></span>
                </v-btn>
              </div>
            </div>
          </template>
        </v-data-table>
      </div>
    </div>

    <!-- dialog สำหรับ เพิ่ม และ แก้ไข -->
    <v-dialog v-model="dialogEvent" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">{{ formTitle }}</span>
        </v-card-title>
        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap>
              <!-- set form กรอกข้อมูลที่นี้ -->
              <v-flex>
                <v-text-field
                  v-model="fus_name"
                  label="Station Name"
                ></v-text-field>
              </v-flex>
              <v-flex>
                <v-select
                  :items="itemsListAirport"
                  v-model="airport"
                  label="Airport"
                  item-text="ap_name"
                  item-value="ap_id"
                  return-object
                ></v-select>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="closeDialog">Cancel</v-btn>
          <v-btn :loading="loading" @click="saveToServer">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- dialog สำหรับลบ -->
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">Delete Confirmation</span>
        </v-card-title>
        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex
                >Delete "{{ fus_name }} ({{ airport.ap_name }})
                ?</v-flex
              >
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="closeDialog">Cancel</v-btn>
          <v-btn :loading="loading" @click="deleteToServer">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div id="partBack" class="wrap-Main">
      <div class="line"></div>
      <div class="box-S4">
        <v-btn text class="ColorTheme-S white--text theme-btn" @click="Back()">
          <span class="I-back"></span>
          <span>Back</span>
        </v-btn>
      </div>
    </div>
  </div>
</template>


<script>
import feathersClientUOA from "../plugins/feathers-client-uoa";
import fillerdate from "../utils/filterdate";

export default {
  data: () => ({
    userdata: "",
    headers: [
      // { value: "fu_id", text: "Id", sortable: true },
      { value: "fus_name", text: "Name", sortable: true },
      { value: "ap_name", text: "Location", sortable: true },
      { value: "action", text: "", sortable: false },
    ],
    defaultValue: {
      fus_name: "",
    },
    query: { $sort: { r_id: -1 } },
    //--end config
    itemsdata: [],
    loading: false,
    dialogEvent: false,
    dialogDelete: false,
    formModel: {},
    mode: "", // มีได้ 2 แบบคือ create กับ edit

    itemsListAirport: [],
    airport: "",
    fus_name: "",
    id: 0,
    total: 0,

    readonly_flight: false,
    readonly_pilot: false,
    readonly_eng: false,
    readonly_shore: false,

    DataFromDB: null,
    ArrCountLegs: [],
  }),
  // props: ["mode", "idflight"],
  computed: {
    formTitle() {
      return this.mode === "create" ? "Add" : "Edit";
    },
  },
  async mounted() {
    this.userdata = await this.$CurrentUser.GetCurrentUser();
    await this.enable_disable();
    //init here
    this.renderUI();
  },
  methods: {
    async enable_disable() {
      if (this.userdata.r_id == 4) {
        this.readonly_flight = true;
      } else if (this.userdata.r_id == 9) {
        this.readonly_eng = true;
      } else if (this.userdata.r_id == 12) {
        this.readonly_pilot = true;
      } else if (this.userdata.r_id == 2) {
        this.readonly_shore = true;
      }
    },
    async renderUI() {
      await this.RenderDataFuelStationFromDB();
      this.countleg();
      try {
        this.id = 0;
        this.itemsdata = [];
        const q = {};
        var res = await feathersClientUOA
          .service("viewfuelstaion")
          .find({ query: q });
        this.itemsdata = res.data;
        res.data.forEach((element) => {
          this.total += element.fus_name;
          //this.itemsdata.push({"fu_id": element.fu_id, "fus_id":element.fus_id,"fus_name": element.fus_name, "fus_name": element.fus_name, "cd": element.cd});
        });
      } catch (error) {
        console.log(error);
        alert("ไม่สามารถขอข้อมูลจาก server ได้");
      }
    },

    async SelectAirport() {
      try {
        const q = {};
        q.retire = 0;
        var res = await feathersClientUOA
          .service("airports")
          .find({ query: q });
        this.itemsListAirport = res.data;
      } catch (error) {
        console.log(error);
        alert("ไม่สามารถขอข้อมูลจาก server ได้");
      }
    },
    async addItem() {
      await this.SelectAirport();
      this.mode = "create";
      this.fus_name = "";
      this.airport = this.itemsListAirport[0];
      this.dialogEvent = true;
    },

    async editItem(item) {
      this.mode = "edit";
      await this.SelectAirport();
      this.id = item.fus_id;
      this.fus_name = item.fus_name;
      this.airport = { ap_id: item.ap_id, ap_name: item.ap_name };
      this.dialogEvent = true;
    },
    async deleteItem(item) {
      this.id = item.fus_id;
      this.fus_name = item.fus_name;
      this.airport = { ap_id: item.ap_id, ap_name: item.ap_name };
      this.dialogDelete = true;
    },

    closeDialog() {
      this.id = 0;
      this.dialogEvent = false;
      this.dialogDelete = false;
    },
    async saveToServer() {
      this.loading = true;
      if (this.mode === "edit") {
        try {
          var dateTime = new Date();

          let dataEdit = {
            fus_name: this.fus_name,
            ap_id: this.airport.ap_id,
            mb: this.userdata.FullName,
            md: new Date(),
          };
          await await feathersClientUOA
            .service("fuelstation")
            .patch(this.id, dataEdit);
          this.renderUI();
        } catch (err) {
          console.log(err);
        } finally {
          this.loading = false;
        }
      } else {
        //Add Data
        try {
          var dateTime = new Date();

          let dataAdd = {
            fus_name: this.fus_name,
            ap_id: this.airport.ap_id,
            cb: this.userdata.FullName,
            cd: dateTime,
            mb: this.userdata.FullName,
            md: dateTime,
          };

          await feathersClientUOA.service("fuelstation").create(dataAdd);
          this.renderUI();
        } catch (err) {
          console.log(err);
        } finally {
          this.loading = false;
        }
      }
      this.closeDialog();
    },
    async deleteToServer() {
      this.loading = true;
      try {
        let dataDelete = {
          mb: this.userdata.FullName,
          md: new Date(),
          retire: 1,
        };

        //alert(this.id + " " + JSON.stringify(dataDelete))
        await feathersClientUOA
          .service("fuelstation")
          .patch(this.id, dataDelete);
        this.renderUI();
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
        this.dialogDelete = false;
      }
    },
    Back() {
      this.$router.go(-1);
    },

    async RenderDataFuelStationFromDB() {
      try {
        var q = {};
        var res = await feathersClientUOA
          .service("viewfuelstaion")
          .find({ query: q });
        this.DataFromDB = res.data[0];
      } catch (error) {
        console.log(error);
        alert("ไม่สามารถขอข้อมูลจาก server ได้");
      }
    },

    async countleg() {
      try {
        var q = {};
        q.f_id = this.idFlightNow;
        var res = await feathersClientUOA
          .service("viewcountleg")
          .find({ query: q });
        this.ArrCountLegs = res.data;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>